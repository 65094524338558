import { lazy } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
  Navigate
} from 'react-router-dom'
import Dashboard from '../layouts/Dashboard'
import { AuthLayout } from '../components/security/AuthLayout'
import { getAuthData } from '../utils/LocalStorageManager'
import Simulator from '../pages/Simulator'
import ManagementClients from '../pages/ManagementClients'

const Session = lazy(() => import('../pages/session/Session'))

const NotFound = lazy(() => import('../pages/utils/NotFound'))

export const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />} loader={async () => defer({ authPromise: getAuthData() })}>
      <Route path="/session" element={<Session />} />
      <Route path="/" element={<Dashboard />}>
        {/* <Route index element={<ManagementClients />} /> */}
        <Route index element={<Simulator />} />
      </Route>
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Route>
  )
)
