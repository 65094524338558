import { Box, Typography, styled } from '@mui/material'

const WrappBox = styled(Box)(({ theme }) => ({
  borderLeft: `${theme.spacing(5)} solid ${theme.palette.primary.main}`,
  display: 'flex',
  alignItems: 'stretch'
}))

const Content = styled('div')(({ theme }) => ({
  width: '46%',
  backgroundColor: '#326267',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  padding: `${theme.spacing(6)} ${theme.spacing(4)}`,
  [theme.breakpoints.down('md')]: {
    width: '60%',
    h1: {
      fontSize: '3rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    h1: {
      fontSize: '2.5rem'
    }
  }
}))

const Span = styled('span')(({ theme }) => ({
  color: `${theme.palette.secondary.main}`
}))

const Bubble = styled('span')(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.contrastText}`,
  position: 'absolute',
  opacity: '0.2',
  borderRadius: '100%'
}))

const BgImage = styled('div')(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center right',
  backgroundRepeat: 'no-repeat',
  width: '54%',
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    content: "''",
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#326267',
    opacity: '0.5'
  },
  [theme.breakpoints.down('md')]: {
    width: '40%'
  },
  [theme.breakpoints.down('sm')]: {
    width: '0%'
  }
}))

interface typeBanner {
  title: string
  subTitleWhite: string
  subTitleYellow: string
  image: string
  paddingContent?: string
  sizeTittle?: string
  sizeSubTittle?: string
}

const Banner = ({
  title,
  subTitleWhite,
  subTitleYellow,
  image,
  paddingContent,
  sizeTittle,
  sizeSubTittle
}: typeBanner) => {
  return (
    <WrappBox>
      <Content sx={{ py: `${paddingContent}` }}>
        <Typography variant="h1" color="primary.contrastText" sx={{ fontSize: `${sizeTittle}` }}>
          {title}
        </Typography>
        <Typography variant="h2" color="primary.contrastText" sx={{ fontSize: `${sizeSubTittle}` }}>
          {subTitleWhite} <Span>{subTitleYellow}</Span>
        </Typography>
        <Bubble sx={{ width: '105px', height: '105px', left: '2%', top: '9%' }} />
        <Bubble
          sx={{
            width: '50px',
            height: '50px',
            left: '9%',
            bottom: '12%',
            display: { xs: 'none', md: 'block' }
          }}
        />
        <Bubble
          sx={{
            width: '50px',
            height: '50px',
            right: '23%',
            top: '9%',
            display: { xs: 'none', md: 'block' }
          }}
        />
        <Bubble
          sx={{
            width: '15rem',
            height: '15rem',
            right: '-9%',
            bottom: '-55%'
          }}
        />
      </Content>
      <BgImage style={{ backgroundImage: `url(${image})` }} />
    </WrappBox>
  )
}

export default Banner
