import { CssBaseline, ThemeProvider } from '@mui/material'
import ReactDOM from 'react-dom/client'
import theme from './theme/theme'
import './resources/fonts.css'
import './resources/custom.css'
import { RouterProvider } from 'react-router-dom'

import { AppRoutes } from './routes/AppRoutes'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <RouterProvider router={AppRoutes} />
  </ThemeProvider>
)
