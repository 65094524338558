import axios from 'axios'
import { getItem } from './LocalStorageManager'
import jwt_decode from 'jwt-decode'
import url from '../url/url'
/**
 * Axion public
 */
const publicFetch = axios.create({
  baseURL: url.base_url + url.api_context
})

/**
 * Axion private - internal parval endpoints
 */
const privateFetch = axios.create({
  baseURL: url.base_url + url.api_context
})

/**
 * Axion private - interceptors
 */
privateFetch.interceptors.request.use(
  async (config) => {
    //validate uri to send token
    const { origin } = new URL(config.baseURL + config.url)
    const allowedOrigins = [url.base_url]
    const authInfo = await getItem('authSMInfo')
    const decodeToken = jwt_decode(authInfo?.tokenInfo?.access_token)
    const expiresAt = decodeToken.exp
    let valid = new Date().getTime() / 1000 < expiresAt

    if (allowedOrigins.includes(origin) && valid) {
      config.headers.Authorization = `Bearer ${authInfo?.tokenInfo?.access_token}`
    }
    return config
  }
  // error=>{
  //   return Promise.reject(error);
  // }
)

export { publicFetch, privateFetch }
