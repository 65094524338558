import {
  Paper,
  Container,
  Typography,
  useTheme,
  Grid,
  TextField,
  Button,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  TableContainer,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es'
import {
  findTitleCodeRequest,
  findNewTitleCodeRequest,
  holidayRequest,
  clientCodeRequest,
  simulationRequest
} from '../services/SimuladorService'
import Logo from '../resources/images/logopv.svg'
import dayjs, { Dayjs } from 'dayjs'
import Axios from 'axios'
import { forwardRef, useEffect, useState } from 'react'
import Banner from '../components/Banner'
import MessageManager from '../components/utils/MessageManager'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import ImageBanner from '../resources/images/customer-management.webp'
// @ts-ignore
import pdfMake from 'pdfmake/build/pdfmake'
// @ts-ignore
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

interface titleInfTypes {
  fullName: string
  denominac: string
  codSivem: string
  coupon: string
  dateEmission: string
  dateExpire: string
  dateCoupon: string
  dateNextCoupon: string
  isin: string
  transmitterName: string
  currency: string
  id: string
  codeTitle: string
}

const titleInfInit = {
  fullName: '',
  denominac: '',
  codSivem: '',
  coupon: '',
  dateEmission: '',
  dateCoupon: '',
  dateNextCoupon: '',
  dateExpire: '',
  isin: '',
  transmitterName: '',
  currency: '',
  id: '',
  codeTitle: ''
}

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
  marginBottom: theme.spacing(4)
}))
const Actions = styled(DialogActions)(({ theme }) => ({
  marginTop: theme.spacing(1),
  borderTop: '1px solid #dee2e6'
}))

export const getBase64ImageFromURL = async (url: any) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')

    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      // @ts-ignore
      ctx.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL('image/png')
      resolve(dataURL)
    }
    img.onerror = (error) => {
      reject(error)
    }
    img.src = url
  })
}

const getAllTables = async (tables: any) => {
  let arrayTables: any = []
  await tables.forEach(async (element: any, index: number) => {
    const getTable = await getTables(element.list)
    await arrayTables.push([
      { text: element.codeTitle, fontSize: 10 },
      { text: element.nameTitle, fontSize: 10 },
      {
        text: `Monto inicial:  ${new Intl.NumberFormat('en-EN').format(element.initialCash)}`,
        fontSize: 10
      },
      { text: `Vencimiento en días:  ${element.days}`, fontSize: 10 },
      {
        text: `Tipo de operación:  ${element.operationType === '1' ? 'Mensual' : 'Vencimiento'}`,
        fontSize: 10
      },
      { text: `Fecha de inicio:  ${element.startDate}`, fontSize: 10 },
      {
        marginBottom: 20,
        table: {
          body: getTable,
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*']
        }
      }
    ])
  })
  return arrayTables
}

const getTables = async (tableList: any) => {
  const tablesVar = {
    style: 'bodyTableSmall',
    border: [false, false, false, false],
    marginTop: 3,
    marginBottom: 2
  }
  let tables: any = [
    [
      {
        text: 'N° Renovación',
        style: 'headTableSmall',
        border: [true, true, false, true],
        fillColor: '#dee2e6'
      },
      {
        text: 'Efectivo',
        style: 'headTableSmall',
        border: [false, true, false, true],
        fillColor: '#dee2e6'
      },
      {
        text: 'Fecha Valor',
        style: 'headTableSmall',
        border: [false, true, false, true],
        fillColor: '#dee2e6'
      },
      {
        text: 'Días',
        style: 'headTableSmall',
        border: [false, true, false, true],
        fillColor: '#dee2e6'
      },
      {
        text: 'Fecha Vencimiento',
        style: 'headTableSmall',
        border: [false, true, false, true],
        fillColor: '#dee2e6'
      },
      {
        text: 'Nota',
        style: 'headTableSmall',
        border: [false, true, false, true],
        fillColor: '#dee2e6'
      },
      {
        text: 'Comentario',
        style: 'headTableSmall',
        border: [false, true, true, true],
        fillColor: '#dee2e6'
      }
    ]
  ]
  await tableList.forEach((item: any, i: number) => {
    tables.push([
      {
        text: item.month,
        style: 'bodyTableSmall',
        fillColor: i % 2 === 0 ? null : '#dee2e6',
        marginTop: 3,
        marginBottom: 2,
        border: [true, false, false, false]
      },
      {
        text: item.cash.toLocaleString('en-US'),
        fillColor: i % 2 === 0 ? null : '#dee2e6',
        ...tablesVar
      },
      {
        text: dayjs(item.valueDate).format('DD/MM/YYYY'),
        fillColor: i % 2 === 0 ? null : '#dee2e6',
        ...tablesVar
      },
      {
        text: item.days,
        fillColor: i % 2 === 0 ? null : '#dee2e6',
        ...tablesVar
      },
      {
        text: dayjs(item.expirationDate).format('DD/MM/YYYY'),
        fillColor: i % 2 === 0 ? null : '#dee2e6',
        ...tablesVar
      },
      {
        text: item.noteCoupon !== '' ? item.noteCoupon : item.note,
        fillColor: i % 2 === 0 ? null : '#dee2e6',
        ...tablesVar
      },
      {
        text: item.comment,
        fillColor: i % 2 === 0 ? null : '#dee2e6',
        style: 'bodyTableSmall',
        border: [false, false, true, false],
        marginTop: 3,
        marginBottom: 2
      }
    ])
  })
  tables.push([
    {
      colSpan: 7,
      text: '',
      border: [true, false, true, true]
    }
  ])
  return tables
}

const defaultValues: any = {
  startDate: new Date(),
  cashValue: '',
  days: '',
  operationType: '1',
  monthsProcessed: '0'
}

const validation = Yup.object().shape({
  startDate: Yup.date().typeError('Campo es requerido'),
  cashValue: Yup.string().required('Campo es requerido'),
  days: Yup.number().required('Campo es requerido').min(1).max(360),
  operationType: Yup.string().required('Campo es requerido')
})

const Simulator = () => {
  const [form, setForm] = useState({ codeClient: '', codeTitle: '', newCodeTitle: '' })
  // const [form, setForm] = useState({
  //   codeClient: 'BON011',
  //   codeTitle: 'ACAP01',
  //   newCodeTitle: 'AES526'
  // })
  const [clientInf, setClientInf] = useState<any>({ fullName: '' })
  const [titleInf, setTitleInf] = useState<titleInfTypes>(titleInfInit)
  const [newTitleInf, setNewTitleInf] = useState<titleInfTypes>(titleInfInit)
  const [dialogDate, setDialogDate] = useState({ open: false, isNew: false })
  const [valueDate, setValueDate] = useState<Dayjs | null>(dayjs())
  const [originalDays, setOriginalDays] = useState<Dayjs | null>(null)
  const [listSimulation, setListSimulation] = useState<any>([])
  const [lastItemSimulation, setLastItemSimulation] = useState<any>({})
  const [holidays, setHolidays] = useState<any>()
  const [alert, setAlert] = useState({ open: false, type: 'success', msg: '', details: '' })
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('sm'))
  const isSm = useMediaQuery(theme.breakpoints.down('md'))

  const {
    register,
    handleSubmit,
    resetField,
    formState,
    setError,
    control,
    watch,
    reset,
    setValue
  } = useForm<any>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(validation)
  })

  const watch_days: any = watch('days')

  useEffect(() => {
    if (watch_days <= 31) {
      setValue('operationType', '1')
    }
  }, [watch_days])

  const { errors } = formState

  const getPDF = async () => {
    const pdf = {
      pageSize: 'A4',
      info: {
        title: 'Simulador Mutuos',
        author: 'Parval',
        subject: 'Simulador Mutuos',
        keywords: ''
      },
      content: [
        {
          image: await getBase64ImageFromURL(Logo),
          width: 100
        },
        { text: 'Simulador Mutuos', style: 'uppercase', alignment: 'center' },
        '\n\n',
        {
          layout: 'noBorders',
          table: {
            widths: [140, '*'],
            body: [
              [
                {
                  text: 'Código Cliente'
                },
                {
                  text: form.codeClient
                }
              ],
              [
                {
                  text: 'Nombre Cliente'
                },
                {
                  text: clientInf.fullName
                }
              ],
              [
                {
                  text: 'Código de título'
                },
                {
                  text: form.codeTitle
                }
              ],
              [
                {
                  text: 'Nombre de título'
                },
                {
                  text: titleInf.fullName
                }
              ],
              [
                {
                  text: 'Fecha de vencimiento'
                },
                {
                  text: titleInf.dateExpire
                }
              ],
              [
                {
                  text: 'Fecha último pago Cupón'
                },
                {
                  text: titleInf.dateCoupon
                }
              ],
              [
                {
                  text: 'Fecha próximo pago Cupón'
                },
                {
                  text: titleInf.dateNextCoupon
                }
              ],
              [
                {
                  text: 'Emisor'
                },
                {
                  text: titleInf.transmitterName
                }
              ]
            ]
          }
        },
        '\n\n',
        await getAllTables(listSimulation)
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true,
          margin: [0, 5]
        },
        textos: {
          fontSize: 10,
          margin: [0, 5]
        },
        uppercase: {
          fontSize: 16,
          bold: true,
          lineHeight: 1.5
        },
        uppercase2: {
          fontSize: 10,
          bold: true,
          lineHeight: 1.5
        },
        upred: {
          fontSize: 12,
          bold: true,
          lineHeight: 1.5,
          color: 'red'
        },
        headTableSmall: {
          fontSize: 7,
          bold: true
        },
        bodyTableSmall: {
          fontSize: 8,
          lineHeight: 1.25
        }
      }
    }
    pdfMake.createPdf(pdf).open()
  }

  const getTitleCode = async () => {
    const today = new Date().toISOString().split('T')[0]
    getHoliday()
    try {
      const response: any = await findTitleCodeRequest(form.codeTitle, today)
      /* const response = await Axios.get(
        `https://parval-simulador-dispatcher.bitcode-enterprise.dev/v1/maintenance/title/find/${form.codeTitle}?cashValue=0&days=1&operationType=1&startDate=${today}`
      ) */
      if (response.dateExpire >= today) {
        if (response.frequencyCoupon === 'S') {
          setTitleInf(response)
        } else {
          setAlert({
            open: true,
            type: 'warning',
            msg: 'La periodicidad del título debe ser semestral.',
            details: ''
          })
        }
      } else {
        setAlert({
          open: true,
          type: 'warning',
          msg: 'El título ya venció.',
          details: ''
        })
        // clear all from data
        setTitleInf(titleInfInit)
      }
    } catch (error) {
      console.log(error)
      setAlert({
        open: true,
        type: 'error',
        msg: 'Código de título no existe',
        details: ''
      })
    }
  }

  const getNewTitleCode = async (event: any) => {
    event.preventDefault()

    if (form.codeTitle !== form.newCodeTitle) {
      try {
        const response: any = await findNewTitleCodeRequest(
          form.newCodeTitle,
          lastItemSimulation.cash,
          lastItemSimulation.missingDays,
          lastItemSimulation.operationType,
          lastItemSimulation.nextDayAfterCoupon
        )

        /* const response = await Axios.get(
          `https://parval-simulador-dispatcher.bitcode-enterprise.dev/v1/maintenance/title/find/${form.newCodeTitle}?cashValue=${lastItemSimulation.cash}&days=${lastItemSimulation.missingDays}&operationType=${lastItemSimulation.operationType}&startDate=${lastItemSimulation.nextDayAfterCoupon}`
        ) */
        if (response.frequencyCoupon === 'S') {
          if (response.daysConsumedBeforeNextCoupon) {
            setNewTitleInf(response)
          } else {
            setDialogDate({ open: false, isNew: true })
            setForm({ ...form, newCodeTitle: '' })
            setAlert({
              open: true,
              type: 'warning',
              msg: 'El codigo de titulo no consume la totalidad del cupón',
              details: ''
            })
          }
        } else {
          setDialogDate({ open: false, isNew: true })
          setAlert({
            open: true,
            type: 'warning',
            msg: 'La periodicidad del título debe ser semestral.',
            details: ''
          })
        }
      } catch (error) {
        console.log(error)
        setDialogDate({ open: false, isNew: true })
        setAlert({
          open: true,
          type: 'error',
          msg: 'Código de título no existe',
          details: ''
        })
      }
    } else {
      setDialogDate({ open: false, isNew: true })
      setForm({ ...form, newCodeTitle: '' })
      setAlert({
        open: true,
        type: 'warning',
        msg: 'El codigo de título actual no puede ser igual al anterior.',
        details: ''
      })
    }
  }

  const getHoliday = async () => {
    setDialogDate({ open: false, isNew: false })
    try {
      const getApi: any = await holidayRequest()
      /* const getApi = await Axios(
        'https://parval-simulador-dispatcher.bitcode-enterprise.dev/v1/maintenance/holiday/0/100'
      ) */
      const formatDays = getApi.content.map((d: any, i: any) => `${d.year}-${d.month}-${d.day}`)
      setHolidays(formatDays)
    } catch (error) {
      setAlert({
        open: true,
        type: 'error',
        msg: 'Error al obtener los dias feriados',
        details: ''
      })
    }
  }

  const getClientCode = async () => {
    try {
      const response = await clientCodeRequest(form.codeClient)
      /* const response = await Axios.get(
        `https://parval-simulador-dispatcher.bitcode-enterprise.dev/v1/maintenance/client/findbycode/${form.codeClient}`
      ) */
      setClientInf(response)
    } catch (error) {
      console.log(error)
      setAlert({
        open: true,
        type: 'error',
        msg: 'Código de cliente no existe',
        details: ''
      })
    }
  }

  const getSimulation = async (data: any) => {
    const formatCash = data.cashValue.split(',').join('')

    try {
      let idTitle = dialogDate.isNew ? '0' : titleInf.id
      let startDate = dayjs(data.startDate).format('YYYY-MM-DD')
      let dataOriginalDays = originalDays || data.days
      const getApi: any = await simulationRequest(
        formatCash,
        data.days,
        idTitle,
        data.operationType,
        startDate,
        data.monthsProcessed,
        dataOriginalDays
      )
      /* const getApi = await Axios(
        `https://parval-simulador-dispatcher.bitcode-enterprise.dev/v1/maintenance/simulator/?cashValue=${formatCash}&days=${
          data.days
        }&idTitle=${dialogDate.isNew ? '0' : titleInf.id}&operationType=${
          data.operationType
        }&startDate=${dayjs(data.startDate).format('YYYY-MM-DD')}&monthsProcessed=${
          data.monthsProcessed
        }&originalDays=${originalDays || data.days}`
      ) */
      // setListSimulation(getApi.data)
      if (dialogDate.isNew) {
        setListSimulation([
          ...listSimulation,
          {
            initialCash: formatCash,
            days: data.days,
            operationType: data.operationType,
            startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
            codeTitle: newTitleInf.codeTitle,
            nameTitle: newTitleInf.fullName,
            list: getApi
          }
        ])
        setValueDate(dayjs())
        setDialogDate((currState) => ({
          ...currState,
          isNew: false
        }))
      } else {
        setOriginalDays(data.days)
        setListSimulation([
          {
            initialCash: formatCash,
            days: data.days,
            operationType: data.operationType,
            startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
            codeTitle: titleInf.codeTitle,
            nameTitle: titleInf.fullName,
            list: getApi
          }
        ])
      }
      const lastData = getApi[getApi.length - 1]

      if (getApi[getApi.length - 1].detectCoupon) {
        setValueDate(dayjs(lastData.nextDayAfterCoupon))
        resetField('days', { defaultValue: lastData.missingDays })
        resetField('startDate', { defaultValue: dayjs(lastData.nextDayAfterCoupon) })
        resetField('monthsProcessed', { defaultValue: lastData.month })
      }
      setLastItemSimulation({ operationType: data.operationType, ...lastData })
    } catch (error) {
      setAlert({
        open: true,
        type: 'error',
        msg: 'Error al intentar la peticion',
        details: ''
      })
    }
    setDialogDate({ open: false, isNew: false })
  }

  function disableWeekends(date: Dayjs) {
    const day = date.day()
    const date2 = date.format('YYYY-M-D')
    return day === 0 || day === 6 || holidays.includes(date2)
  }

  return (
    <>
      <Banner
        title={`Simulador Mutuos`}
        subTitleWhite={``}
        subTitleYellow={``}
        image={ImageBanner}
      />
      <Container maxWidth="lg" sx={{ px: 2, py: 6 }}>
        <Paper sx={{ padding: theme.spacing(5), borderRadius: theme.spacing(2) }}>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} sm={3} md={2}>
              <Typography variant="body1" color="initial">
                Código Cliente
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={3}>
              <TextField
                fullWidth
                size="small"
                name="codeClient"
                value={form.codeClient}
                onChange={(e) => setForm({ ...form, codeClient: e.target.value })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={7}
              display="flex"
              justifyContent={isXs ? 'flex-end' : 'flex-start'}
            >
              <Button variant="contained" color="primary" onClick={getClientCode}>
                Buscar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="body1" color="initial">
                Nombre Cliente
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography
                variant="body1"
                color="initial"
                sx={{ borderBottom: 'solid 1px #000', pt: 2, pb: 0.5, mb: 1.5 }}
              >
                {clientInf.fullName}
              </Typography>
            </Grid>
          </Grid>
          {clientInf.fullName !== '' && (
            <>
              <Divider sx={{ my: 8 }} />
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial">
                    Código Titulo
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7} md={3}>
                  <TextField
                    fullWidth
                    size="small"
                    name="codeTitle"
                    value={form.codeTitle}
                    error={!!Boolean(errors.codeTitle)}
                    onChange={(e) => setForm({ ...form, codeTitle: e.target.value })}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={7}
                  display="flex"
                  justifyContent={isXs ? 'flex-end' : 'flex-start'}
                >
                  <Button variant="contained" color="primary" onClick={getTitleCode}>
                    Buscar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Typography variant="body1" color="initial">
                    Nombre Completo
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.fullName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial">
                    Denominación
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.denominac}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial" align="left" paddingLeft="90px">
                    Código Siven
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.codSivem}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial">
                    Cupón
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.coupon}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial" align="left" paddingLeft="90px">
                    Fec Emision
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.dateEmission}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial">
                    Fecha último pago Cupón
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.dateCoupon}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial" align="left" paddingLeft="90px">
                    Isin
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.isin}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial">
                    Fecha próximo pago Cupón
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.dateNextCoupon}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial" align="left" paddingLeft="90px">
                    Moneda
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.currency}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Typography variant="body1" color="initial">
                    Fecha de Expiración
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={4}>
                  <Typography
                    variant="body1"
                    color="initial"
                    sx={{
                      borderBottom: 'solid 1px #000',
                      pt: 2,
                      pb: 0.5,
                      mb: 1.5,
                      minHeight: 45
                    }}
                  >
                    {titleInf?.dateExpire}
                  </Typography>
                </Grid>
                {titleInf.fullName !== '' && (
                  <Grid item xs={12} md={6} display={'flex'} justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        resetField('startDate', { defaultValue: dayjs(new Date()) })
                        resetField('days', { defaultValue: '' })
                        resetField('monthsProcessed', { defaultValue: '0' })
                        setOriginalDays(null)
                        reset()
                        setDialogDate({ open: true, isNew: false })
                      }}
                    >
                      Simular
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          <Divider sx={{ display: listSimulation.length > 0 ? 'block' : 'none', my: 8 }} />
          {listSimulation?.map((item: any, i: any) => (
            <Stack key={i}>
              <Typography variant="body1" color="initial">
                Código Titulo: {item.codeTitle}
              </Typography>
              <Typography variant="body1" color="initial">
                Nombre Titulo: {item.nameTitle}
              </Typography>
              <TableContainer sx={{ overflow: 'auto' }}>
                <Table sx={{ minWidth: 800 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>N° Renovación</TableCell>
                      <TableCell>Efectivo</TableCell>
                      <TableCell>Fecha Valor</TableCell>
                      <TableCell>Días</TableCell>
                      <TableCell>Fecha Vencimiento</TableCell>
                      <TableCell>Nota</TableCell>
                      <TableCell>Comentario</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item?.list?.map((row: any, i: number) => (
                      <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{row.month}</TableCell>
                        <TableCell>{row.cash.toLocaleString('en-US')}</TableCell>
                        <TableCell>{dayjs(row.valueDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{row.days}</TableCell>
                        <TableCell>{dayjs(row.expirationDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>{row.noteCoupon !== '' ? row.noteCoupon : row.note}</TableCell>
                        <TableCell>{row.comment}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          ))}
          {listSimulation.length > 0 && (
            <Stack>
              {listSimulation[listSimulation.length - 1].list[
                listSimulation[listSimulation.length - 1].list.length - 1
              ].noteCoupon && (
                <Stack alignItems={'flex-end'}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setDialogDate({ open: true, isNew: true })}
                  >
                    Utilizar otro titulo
                  </Button>
                </Stack>
              )}

              <Stack alignItems={'flex-end'} mt={5}>
                <Button variant="contained" color="primary" onClick={getPDF}>
                  Descargar
                </Button>
              </Stack>
            </Stack>
          )}
        </Paper>
      </Container>
      {alert.open && (
        <MessageManager
          // @ts-ignore
          type={alert.type}
          msg={alert.msg}
          details={alert.details}
          callback={() => setAlert({ ...alert, open: false })}
        />
      )}
      <Dialog
        open={dialogDate.open}
        onClose={() => setDialogDate({ open: false, isNew: false })}
        fullWidth
        maxWidth={'xs'}
      >
        <Title align="center" sx={{ mb: 0 }}>
          Simulador
        </Title>

        {dialogDate.isNew && (
          <form onSubmit={getNewTitleCode}>
            <DialogContent
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pb: 0,
                pt: 1
              }}
            >
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label="Nuevo Código Titulo"
                  type="text"
                  variant="standard"
                  value={form.newCodeTitle}
                  onChange={(e) => setForm({ ...form, newCodeTitle: e.target.value })}
                />
              </FormControl>
              <Stack alignItems={'flex-end'} mt={2}>
                <Button variant="contained" color="primary" type="submit">
                  Buscar
                </Button>
              </Stack>
            </DialogContent>
            <Stack pt={2} px={2.6}>
              <Typography variant="body1" color="initial">
                {newTitleInf.fullName}
              </Typography>
            </Stack>
          </form>
        )}
        <form>
          <DialogContent
            sx={{
              display: dialogDate.isNew && newTitleInf.fullName === '' ? 'none' : 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <Controller
              name={'cashValue'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <NumericFormat
                    customInput={TextField}
                    onValueChange={(values) => {
                      onChange(values.formattedValue)
                    }}
                    thousandSeparator=","
                    decimalScale={0}
                    value={value}
                    size="small"
                    label="Monto inicial"
                    variant="standard"
                    fullWidth
                    disabled={dialogDate.isNew}
                  />
                  {errors.cashValue?.type === 'required' && (
                    <FormHelperText>{errors.cashValue.message?.toString()}</FormHelperText>
                  )}
                  {errors.cashValue?.type === 'typeError' && (
                    <FormHelperText>Debe ingresar un digito valido</FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name={'days'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    label="Vencimiento en días"
                    type="number"
                    variant="standard"
                    defaultValue={value}
                    disabled={dialogDate.isNew}
                    sx={{ mt: 2 }}
                    {...register('days')}
                    error={errors.days && Boolean(errors.days)}
                  />
                  {errors.days?.type === 'required' && (
                    <FormHelperText>{errors.days.message?.toString()}</FormHelperText>
                  )}
                  {errors.days?.type === 'min' && (
                    <FormHelperText>La cantidad de dias tiene que ser entre 1 y 360</FormHelperText>
                  )}
                  {errors.days?.type === 'max' && (
                    <FormHelperText>La cantidad de dias no puede ser mayor a 360</FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="operationType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl variant="standard" sx={{ minWidth: 120, mt: 2 }}>
                  <InputLabel id="operationType">Tipo de operación</InputLabel>
                  <Select
                    labelId="operationType"
                    value={value}
                    disabled={watch_days <= 31 || dialogDate.isNew}
                    defaultValue={value}
                    {...register('operationType')}
                  >
                    <MenuItem value="1">Mensual</MenuItem>
                    <MenuItem value="2">Vencimiento</MenuItem>
                  </Select>
                  {errors.operationType && (
                    <FormHelperText>{errors.operationType.message?.toString()}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="monthsProcessed"
              control={control}
              render={() => <input type="hidden" />}
            />
            <Controller
              name={'startDate'}
              control={control}
              render={({ field, ...props }) => (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DatePicker
                      openTo="day"
                      label="Fecha de inicio"
                      disablePast
                      shouldDisableDate={disableWeekends}
                      value={valueDate}
                      disabled={dialogDate.isNew}
                      onChange={(date) => {
                        field.onChange(date)
                        setValueDate(date)
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth label="Estado" variant="standard" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
              {...(errors.startDate && (
                <FormHelperText>{errors.startDate.message?.toString()}</FormHelperText>
              ))}
            />
          </DialogContent>
          <Actions
            sx={{
              padding: 2,
              display: dialogDate.isNew && newTitleInf.fullName === '' ? 'none' : 'block'
            }}
          >
            <Button variant="contained" color="primary" onClick={handleSubmit(getSimulation)}>
              Simular
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setDialogDate({ open: false, isNew: false })
                setForm({ ...form, newCodeTitle: '' })
                setNewTitleInf(titleInfInit)
              }}
            >
              Cancelar
            </Button>
          </Actions>
        </form>
      </Dialog>
    </>
  )
}

export default Simulator
