import { URL } from '../utils/UrlConstant'
import { privateFetch } from '../utils/CustomFetch.js'
import { properties } from '../utils/Properties_es'
import { CONSTANT } from '../utils/Constant'
import { formatDate } from '../utils/UtilsFunctions'
import url from '../url/url'


export const findTitleCodeRequest = async (title:string, today:string) => {
  let responseData = {}
  await privateFetch
    .get(URL.TITLE_CODE_REQUEST(title, today), {
      headers: {
        ...CONSTANT.HEADER_TYPE
      }
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error)
    })
  return responseData
}

export const findNewTitleCodeRequest = async (newCodeTitle:string, cash:string, missingDays:string, operationType: string, nextDayAfterCoupon:string) => {
  let responseData = {}
  await privateFetch
    .get(URL.NEW_TITLE_CODE_REQUEST(newCodeTitle, cash, missingDays, operationType, nextDayAfterCoupon), {
      headers: {
        ...CONSTANT.HEADER_TYPE
      }
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error)
    })
  return responseData
}

export const holidayRequest = async () => {
  let responseData = {}
  await privateFetch
    .get(URL.HOLIDAY_REQUEST, {
      headers: {
        ...CONSTANT.HEADER_TYPE
      }
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error)
    })
  return responseData
}

export const clientCodeRequest = async (codeClient:string) => {
  let responseData = {}
  await privateFetch
    .get(URL.CLIENT_CODE_REQUEST(codeClient), {
      headers: {
        ...CONSTANT.HEADER_TYPE
      }
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error)
    })
  return responseData
}


export const simulationRequest = async (formatCash:string, days:string, idTitle:string, operationType: string, startDate:string, monthsProcessed:string, originalDays:string) => {
  let responseData = {}
  await privateFetch
    .get(URL.SIMULATION_REQUEST(formatCash, days, idTitle, operationType, startDate, monthsProcessed, originalDays), {
      headers: {
        ...CONSTANT.HEADER_TYPE
      }
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error)
    })
  return responseData
}
