import { URL } from '../../utils/UrlConstant'
import { publicFetch } from '../../utils/CustomFetch.js'
import { properties } from '../../utils/Properties_es'
import { CONSTANT } from '../../utils/Constant'
import qs from 'qs'
import { Buffer } from 'buffer'

export const SVSessionValidateRequest = async (email, sessionCode) => {
  let responseData = {}
  const requestBody = {
    username: email,
    sessionCode: sessionCode
  }
  await publicFetch
    .post(URL.SESSIONVALIDATE_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE2 }
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch((error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 500)) {
        throw new Error(properties.com_parval_label_login_sessioncode_error)
      }
      throw new Error(properties.com_parval_label_login_error)
    })

  return responseData
}
