import { CONSTANT } from "./Constant";
export const URL = {

    APPLOGIN_REQUEST: CONSTANT.DISPATCHER_SECURITY_PREFIX + "/oauth/token",
    SESSIONVALIDATE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",
    /** PARVAL EXTERNAL API */
    CLIENTACCOUNTS_REQUEST: "/user/cuentas",

    /** maintenance urls */


    REQUEST_CERTIFICATE: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${id ? id : ''}`,

      REQUEST_CLIENT_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/`,
    REQUEST_CLIENT: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id ? id : ''}`,
    CREATE_CLIENT: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/create`,
    UPLOAD_DOCUMENTS: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/upload`,



    TITLE_CODE_REQUEST: (title:string, today:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/title/find/${title}?cashValue=0&days=1&operationType=1&startDate=${today}`,

    NEW_TITLE_CODE_REQUEST: (newCodeTitle:string, cash:string, missingDays:string, operationType: string, nextDayAfterCoupon:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/title/find/${newCodeTitle}?cashValue=${cash}&days=${missingDays}&operationType=${operationType}&startDate=${nextDayAfterCoupon}`,

    HOLIDAY_REQUEST: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/holiday/0/100`,

    CLIENT_CODE_REQUEST: (codeClient:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/findbycode/${codeClient}`,

    SIMULATION_REQUEST: (formatCash:string, days:string, idTitle:string, operationType: string, startDate:string, monthsProcessed:string, originalDays:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/simulator/?cashValue=${formatCash}&days=${days}&idTitle=${idTitle}&operationType=${operationType}&startDate=${startDate}&monthsProcessed=${
      monthsProcessed}&originalDays=${originalDays}`,


}
