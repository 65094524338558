import { useState, createContext, useMemo, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { requestTypes } from '../types/types'
import { setAuthData } from '../utils/LocalStorageManager'
import jwt_decode from 'jwt-decode'
import url from '../url/url'

const initialState = {
  isLoading: false,
  errorMsg: '',
  successMsg: ''
}

const AppContext = createContext<requestTypes>(initialState)

const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData)
  const [isLoading, setLoading] = useState(initialState.isLoading)
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg)
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg)
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: '',
    modalObject: null
  })

  //borrar
  const [datosC, setDatosC] = useState<any>()

  const [authenticate, setAuthenticate] = useState(authData != null)
  const navigate = useNavigate()

  let datos = {
    id: 17,
    name: 'Alex Ramirez',
    status: 'ACTIVE',
    tokenInfo: {
      access_token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyJyZWFkIiwid3JpdGUiLCJhbGwiXSwiZXhwIjoxNjgwMjc4MDY1LCJqdGkiOiI2ZDMyZDE1MC1iNmFjLTQ1ZjAtOGZmYy1lYzk3YWNlYTY4NjkiLCJjbGllbnRfaWQiOiJmcm9udGVuZGFwcCJ9.GJCD8HaQxqB6Q6zbn5zDR7KrzFZOavQmFAmC-0Q-_4Q',
      expires_in: 86399,
      jti: '6d32d150-b6ac-45f0-8ffc-ec97acea6869',
      scope: 'read write all',
      token_type: 'bearer'
    },
    userRole: {
      adminLogin: true,
      clientLogin: true,
      description: 'Rol de Administrador',
      id: 1,
      roleName: 'Administrador'
    },
    username: 'alex.ramirez@bitcode-enterprise.com'
  }

  const loginProvider = async (loginData: any) => {
    try {
      //save login data in storage
      const authData = await setAuthData('authSMInfo', loginData)
      if (!authData) {
        setAuthenticate(false)
        return
      }
      const decodeToken: any = jwt_decode(authData?.tokenInfo?.access_token)
      const expiresAt = decodeToken?.exp
      let valid = new Date().getTime() / 1000 < expiresAt
      if (valid) {
        setAuthenticate(valid)
        setAuthInfo(authData)
        navigate('/', { replace: true })
        return
      }
    } catch (error) {
      console.log(error)
    }
    setAuthenticate(false)
  }

  // useLayoutEffect(() => {
  //   //@ts-ignore
  //   loginProvider(datos)
  // }, [])

  const logoutProvider = async (e: any) => {
    e.preventDefault()
    await setAuthData('authSMInfo', null)
    setAuthenticate(false)
    //@ts-ignore
    window.location.replace(url.session_base_url)
  }

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg('')
  }

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg('')
  }

  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
      datosC,
      setDatosC
    }),
    // eslint-disable-next-line
    [authInfo, authenticate, isLoading, errorMsg, successMsg, modalData]
  )

  return <AppContext.Provider value={formContextValues}>{children}</AppContext.Provider>
}

export { AppProvider }

export default AppContext
