import { createTheme } from '@mui/material/styles'

let theme = createTheme()

interface customGrey {
  greyDue: {
    main: string
    secondary?: string
    light?: string
    dark?: string
  }
}

declare module '@mui/material/styles' {
  interface Palette extends customGrey {}
  interface PaletteOptions extends customGrey {}
}

theme = createTheme({
  palette: {
    primary: {
      main: '#f1b80e',
      light: '#FFFFFF',
      dark: '#f1b80e',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#BCBEC0',
      light: '#FFFFFF',
      dark: '#5C5D60',
      contrastText: '#ffffff'
    },
    info: {
      main: '#f1b80e',
      light: '#FFFFFF',
      dark: '#3e627c',
      contrastText: '#ffffff'
    },
    greyDue: {
      main: '#A2A2A2',
      dark: '#212529',
      light: '#ABABAB'
    },
    success: {
      main: '#d32f2f'
    },
    text: {
      primary: '#5d5e60',
      secondary: '#e4e8ed'
    },
    background: {
      default: '#fff'
    },
    common: {
      white: '#fff'
    }
  },
  typography: {
    fontFamily: 'D-din',
    h1: {
      fontSize: '3.5125rem',
      fontWeight: 700,
      fontFamily: 'D-din'
    },
    h2: {
      fontSize: '2.7125rem',
      fontWeight: 700,
      fontFamily: 'D-dinCondesed'
    },
    h3: {
      fontSize: '1.5rem'
      //fontSize: '1.3125rem'
    },
    h4: {
      fontSize: '1.3125rem',
      fontWeight: 700,
      fontFamily: 'D-dinCondesed'
    },
    h5: {
      fontSize: '1.1125rem'
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem'
    },
    body1: {
      fontSize: '1rem'
    },
    body2: {
      fontSize: '.8125rem'
      //color: '#666666'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1325,
      xl: 1440
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: theme.palette.primary.contrastText,
          color: '#5d5e60'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#212529',
          opacity: '.25',
          margin: '1rem 0'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
          borderRight: '1px solid #e4e8ed'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '14px',
          marginRight: '0.75rem'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          /* '& label': {
            color: '#5d5e60',
          } */
          '& textarea': {
            paddingBottom: '0'
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: '0',
          marginBottom: '0'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: '2px solid #898788'
          },
          '& .MuiInput-underline:after': {
            borderBottom: '2px solid #f1b80e',
            transition: 'none'
          },
          '& label': {
            color: '#5d5e60'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: '2px solid #CACACA'
          }
        },
        underline: {
          '&:before': {
            borderBottom: '2px solid #CACACA'
          }
        },
        input: {
          padding: ' 4px 0 10px',
          color: '#5d5e60',
          '& .MuiInput-underline:before': {
            borderBottom: '2px solid #CACACA'
          },
          '&:focus': {
            color: '#212529'
          },
          '&::placeholder': {
            color: '#6c757d',
            opacity: 1,
            fontFamily: 'D-din'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: '2px solid #CACACA'
          }
        },
        input: {
          padding: ' 4px 0 10px',
          color: '#5d5e60',
          '& .MuiInput-underline:before': {
            borderBottom: '2px solid #CACACA'
          },
          '&:focus': {
            color: '#212529'
          },
          '&::placeholder': {
            color: 'inherit'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: ' 4px 0 10px'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#5d5e60',
          '&.Mui-focused': {
            color: '#5d5e60'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: '#f7f6f5'
          //padding: theme.spacing(2.5)
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginBottom: '1rem',
          background: '#fff',
          border: '1px solid #dee2e6'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child th, :last-child td, th, td': {
            border: '1px solid #dee2e6!important'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.9375rem',
          textAlign: 'center',
          color: '#86939e',
          fontFamily: 'D-din',
          padding: `${theme.spacing(1.875)} ${theme.spacing(1.5)}`,
          borderBottom: '1px solid #dee2e6'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#e1e8ee',
          '& .MuiTableCell-head': {
            fontFamily: 'D-dinExp',
            color: '#212529',
            fontWeight: '700',
            fontSize: '0.875rem',
            padding: `${theme.spacing(1.625)} ${theme.spacing(1.5)}`
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          color: '#d32f2f'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: ' 4px 0 2px',
          '& .MuiInput-underline:before': {
            borderBottom: '2px solid #CACACA'
          }
        }
      }
    },
    /* MuiButton: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(1.75)} ${theme.spacing(3)}`,
          lineHeight: '1.5'
        }
      }
    }, */
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '32px',
          boxShadow: 'none!important',
          fontWeight: 400,
          '&.MuiButton-containedPrimary': {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#f1b80e',
            padding: '2px 20px',
            '&:hover': {
              background: '#ffffff',
              color: '#5C5D60',
              borderColor: '#f1b80e'
            }
          },
          '&.MuiButton-containedSecondary': {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#BCBEC0',
            padding: '2px 20px'
          }
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected:hover': {
            backgroundColor: '#f1b80e'
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'inherit!important',
            opacity: '0.5'
          },
          '& .MuiTouchRipple-root': {
            display: 'none'
          }
        }
      }
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          display: 'none!important'
        }
      }
    }
  }
})

export default theme
