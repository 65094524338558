import { useEffect } from 'react'
import Swal from 'sweetalert2'

interface MessageTypes {
  type: 'success' | 'error' | 'warning' | 'info' | 'question'
  msg: string
  details?: string
  callback?: () => void
}

const MessageManager = ({ type, msg, details, callback }: MessageTypes) => {
  useEffect(() => {
    showMessage()
    // eslint-disable-next-line
  }, [])

  const showMessage = () => {
    Swal.fire({
      title: `<span style="font-weight: 400">${msg}</span>`,
      text: details,
      icon: type,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#f1b80e'
    }).then(() => callback && callback())
  }

  return <></>
}

export default MessageManager
