import { Suspense } from 'react'
import { Await, useLoaderData, useOutlet } from 'react-router-dom'
import { AppProvider } from '../../contexts/AppContext'
import Loading from '../utils/Loading'

export const AuthLayout = () => {
  const outlet = useOutlet()

  const { authPromise }: any = useLoaderData()

  return (
    <Suspense fallback={<Loading />}>
      <Await
        resolve={authPromise}
        children={(authObject) => <AppProvider authData={authObject}>{outlet}</AppProvider>}
      />
    </Suspense>
  )
}
